<div class="d-flex justify-content-between header-container align-items-center"
     [ngClass]="{'archive-mode': isArchived}">
  <sm-toggle-archive *ngIf="!hideArchiveToggle"
                     [showArchived]="isArchived"
                     [class.hide-item]="sharedView"
                     (toggleArchived)="archivedChanged($event)"></sm-toggle-archive>
  <sm-project-context-navbar *ngIf="!hideCreateNewButton"
                             [class.hide-item]="sharedView"
                             activeFeature="models" [archivedMode]="isArchived"></sm-project-context-navbar>
  <div class="d-flex justify-content-end align-items-center right-buttons">
    <sm-model-custom-cols-menu
      [hidden]="minimizedView || sharedView"
      [tableCols]="tableCols"
      (selectedTableColsChanged)="selectedTableColsChanged.emit($event)"
    ></sm-model-custom-cols-menu>
        <sm-refresh-button
          [autoRefreshState]="autoRefreshState"
          [allowAutoRefresh]="true"
          (refreshList)="refreshListClicked.emit()"
          (setAutoRefresh)="setAutoRefresh.emit($event)"
        >
        </sm-refresh-button>
  </div>
</div>
