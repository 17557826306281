<mat-drawer-container class="settings-container">
  <mat-drawer mode="side" opened>
    <mat-list class="pointer" style="padding-top: 24px;">
      <mat-list-item routerLinkActive="active" routerLink="profile"> Profile </mat-list-item>
      <mat-list-item routerLinkActive="active" routerLink="webapp-configuration"> Configuration </mat-list-item>
      <mat-list-item routerLinkActive="active" routerLink="workspace-configuration"> Workspace </mat-list-item>
    </mat-list>
  </mat-drawer>
  <mat-drawer-content>
    <div class="section">
      <div class="content">
        <router-outlet></router-outlet>
      </div>
    </div>
    <sm-admin-footer></sm-admin-footer>
  </mat-drawer-content>
</mat-drawer-container>
