<script src="../../../models/shared/models-table/models-table.component.ts"></script>
<sm-experiment-menu-extended #contextMenuExtended
                    *ngIf="!disableContextMenu"
                    [experiment]="contextExperiment"
                    [selectedExperiment]="selectedExperiment"
                    [selectedExperiments]="selectedExperiments"
                    [selectedDisableAvailable]="selectedExperimentsDisableAvailable"
                    [numSelected]="selectedExperiments.length"
                    [tagsFilterByProject]="tagsFilterByProject$ | async"
                    [projectTags]="projectTags$ | async"
                    [companyTags]="companyTags$ | async"
                    (tagSelected)="addTag($event)"
                    (menuOpened)="setContextMenuStatus(true)"
                    (menuClosed)="setContextMenuStatus(false)"
                    [activateFromMenuButton]="false"
                    [minimizedView]="true"
></sm-experiment-menu-extended>
<div class="table-container" [class.card-view]="minimizedView" #tableContainer>
  <sm-table
    [selectionMode]="selectionMode"
    [tableData]="experiments"
    [columnsOrder]="colsOrder"
    [reorderableColumns]="reorderableColumns"
    [resizableColumns]="true"
    [columns]="tableCols"
    [lazyLoading]="true"
    [minimizedView]="minimizedView"
    [minimizedTableHeader]="'EXPERIMENT LIST'"
    [noMoreData]="noMoreExperiments"
    [selection]="selectedExperiment"
    [activeContextRow]="contextExperiment"
    [contextMenuOpen]="contextMenuActive"
    [checkedItems]="selectedExperiments"
    [keyboardControl]="true"
    [sortFields]="tableSortFields"
    (rowSelectionChanged)="onRowSelectionChanged($event)"
    (loadMoreClicked)="onLoadMoreClicked()"
    (onRowRightClick)="onContextMenu($event)"
    (colReordered)="columnsReordered.emit($event)"
    (sortChanged)="onSortChanged($event.isShift, $event.field)"
    (columnResized)="columnResized.emit($event)"
  >
    <!--HEADER TEMPLATE-->
    <ng-template let-col pTemplate="cardFilter">
      <sm-table-card-filter-template [options]="filtersOptions"
                                     [filterMatch]="filtersMatch"
                                     [value]="filtersValues"
                                     [subValue]="filtersSubValues[EXPERIMENTS_TABLE_COL_FIELDS.TAGS]"
                                     [columns]="initialColumns"
                                     fixedOptionsSubheader="System tags"
                                     (subFilterChanged)="tableFilterChanged({id:'system_tags'}, $event)"
                                     (filterChanged)="tableAllFiltersChanged($event)"
                                     (menuOpened)="columnFilterOpened($event)"
                                     (menuClosed)="columnFilterClosed($event)"
      ></sm-table-card-filter-template>
    </ng-template>

    <ng-template let-col pTemplate="checkbox">
      <sm-checkbox-control
        *ngIf="col.headerType === colHeaderTypeEnum.checkBox"
        class="checkbox-col header"
        fieldName="selected"
        [formData]="selectionState"
        (formDataChanged)="headerCheckboxClicked()"
        inputClassName="table-check-box select-col-checkbox"
      ></sm-checkbox-control>
      <div class="al-icon al-ico-dropdown-arrow sm drop-down" [matMenuTriggerFor]="selectionMenu"></div>
      <mat-menu class="light-theme" #selectionMenu="matMenu">
        <div class="menu-title">Select from project</div>
        <button mat-menu-item (click)="selectAll()">All</button>
        <button mat-menu-item (click)="emitSelection([])">None</button>
        <button mat-menu-item (click)="selectAll(true)">All matching filter</button>
      </mat-menu>
    </ng-template>
    <!--FILTER TEMPLATE-->

    <ng-template let-col pTemplate="sort-filter">
      <sm-table-filter-sort-template
        [column]="col"
        [value]="filtersValues[col.id]"
        [searchValue]="searchValues[col.id]"
        [options]="filtersOptions[col.id] | filter:searchValues[col.id]:'label'"
        [sortOrder]="tableSortFieldsObject[col.id]"
        [tooltip]="col.metric_hash || col.isParam"
        [fixedOptionsSubheader]="col.id === EXPERIMENTS_TABLE_COL_FIELDS.TAGS ? 'System tags' : ''"
        [andFilter]="col.andFilter ? filtersMatch[col.id] === 'AND' : null"
        (sortOrderChanged)="onSortChanged($event, col.id)"
        (filterChanged)="tableFilterChanged(col, $event);"
        (searchValueChanged)="searchValueChanged($event, col.id)"
        (menuOpened)="columnFilterOpened(col)"
        (menuClosed)="columnFilterClosed(col)"
      >
      </sm-table-filter-sort-template>
    </ng-template>

    <!--BODY TEMPLATE-->

    <ng-template let-col
                 let-experiment="rowData"
                 pTemplate="body">
      <ng-container [ngSwitch]="col.id">
        <ng-container *ngSwitchCase="EXPERIMENTS_TABLE_COL_FIELDS.TYPE">
          <sm-experiment-type-icon-label class="w-100" [type]="experiment?.type"></sm-experiment-type-icon-label>
        </ng-container>
        <ng-container *ngSwitchCase="EXPERIMENTS_TABLE_COL_FIELDS.NAME">
          <div class="d-flex align-items-center pr-3">
            <div
              class="experiment-name"
              [class.italic]="isDevelopment(experiment)"
              matTooltipPosition="above"
              [smTooltip]="experiment.name">
              {{experiment.name}}
            </div>
            <i *ngIf="experiment?.system_tags.includes('shared')" class="fas fa-link p-1 ml-1"></i>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="EXPERIMENTS_TABLE_COL_FIELDS.TAGS">
          <sm-tag-list [tags]="experiment.tags" [sysTags]="getSystemTags(experiment)"></sm-tag-list>
        </ng-container>
        <ng-container *ngSwitchCase="EXPERIMENTS_TABLE_COL_FIELDS.USER">
          {{experiment.user?.name ? experiment.user?.name : 'Unknown User'}}
        </ng-container>
        <span *ngSwitchCase="EXPERIMENTS_TABLE_COL_FIELDS.PROJECT"
              matTooltipPosition="above"
              [smTooltip]="experiment.project?.name">
          {{experiment.project?.name}}
        </span>
        <ng-container *ngSwitchCase="EXPERIMENTS_TABLE_COL_FIELDS.STARTED">
          <span matTooltipPosition="above"
                [smTooltip]="experiment.started | date : TIME_FORMAT_STRING">{{experiment.started | timeAgo}}</span>
        </ng-container>
        <ng-container *ngSwitchCase="EXPERIMENTS_TABLE_COL_FIELDS.LAST_UPDATE">
          <span matTooltipPosition="above"
                [smTooltip]="experiment.last_update | date : TIME_FORMAT_STRING">{{experiment.last_update | timeAgo}}</span>
        </ng-container>
        <ng-container *ngSwitchCase="EXPERIMENTS_TABLE_COL_FIELDS.ACTIVE_DURATION">
          {{experiment.active_duration | duration}}
        </ng-container>
        <ng-container *ngSwitchCase="EXPERIMENTS_TABLE_COL_FIELDS.COMMENT">
          <span matTooltipPosition="above" [smTooltip]="experiment.comment">{{experiment.comment}}</span>
        </ng-container>
        <ng-container *ngSwitchCase="EXPERIMENTS_TABLE_COL_FIELDS.SELECTED">
          <sm-checkbox-control
            class="checkbox-col"
            fieldName="selected"
            [smTooltip]="selectionReachedLimit && !isRowSelected(experiment)? 'Reached selection limit': null"
            [disabled]="selectionReachedLimit && !isRowSelected(experiment)"
            [formData]="isRowSelected(experiment)"
            (formDataChanged)="rowSelectedChanged($event, experiment)"
            inputClassName="table-check-box table-body">
          </sm-checkbox-control>
        </ng-container>
        <ng-container *ngSwitchCase="EXPERIMENTS_TABLE_COL_FIELDS.STATUS">
          <sm-experiment-status-icon-label [status]="experiment?.status"></sm-experiment-status-icon-label>
        </ng-container>
        <ng-container *ngSwitchCase="EXPERIMENTS_TABLE_COL_FIELDS.PARENT">
          <span
            class="parent-name"
            *ngIf="experiment?.parent?.id"
            matTooltipPosition="above"
            [smTooltip]="experiment.parent.project?.name? experiment.parent.project.name + ' / ' + experiment.parent.name : experiment.parent.name"
          >{{experiment.parent.name}}</span>
        </ng-container>
        <!--  Case for hyper params and custom metrics/variants  -->
        <ng-container *ngSwitchDefault>
          <sm-hyper-param-metric-column [roundedMetricValue]="roundedMetricValues[col.id]"
                                        [col]="col"
                                        [experiment]="experiment"
                                        (expandClicked)="columnsWidthChanged($event)">
          </sm-hyper-param-metric-column>
        </ng-container>
      </ng-container>
    </ng-template>

    <ng-template let-experiment="rowData" let-selected="selected" let-rowNumber="rowNumber" pTemplate="card">
      <sm-table-card class="flex-grow-1" [selected]="selected"
                     [rowNumber]="rowNumber"
                     [cardName]="experiment.name"
                     [columns]="tableCols"
                     [rowData]="experiment"
                     [checked]="isRowSelected(experiment)"
                     [activeContextRow]="contextExperiment"
                     [contextMenuOpen]="contextMenuActive"
      >
        <div sm-name-icon><i *ngIf="experiment?.system_tags.includes('shared')" class="fas fa-link p-1 ml-1"></i></div>
        <sm-tag-list class="mb-2" [sysTags]="getSystemTags(experiment)" [tags]="experiment?.tags"></sm-tag-list>
        <div class="d-flex justify-content-between compact-columns-data" compact-columns-data>
          <span class="ellipsis">Updated {{experiment.last_update | timeAgo}}<span class="dot">●</span>Created by {{experiment.user?.name || 'Unknown User'}}
          </span>
          <ng-container *ngIf="experiment.last_iteration">
            <span>{{experiment.last_iteration}} Iterations</span>
          </ng-container>
        </div>
        <sm-experiment-type-icon-label [type]="experiment?.type" [showLabel]="false"></sm-experiment-type-icon-label>
        <sm-experiment-status-icon-label [status]="experiment?.status"></sm-experiment-status-icon-label>
        <sm-checkbox-control fieldName="selected" [formData]="isRowSelected(experiment)" class="checkbox-align-top"
                             (formDataChanged)="rowSelectedChanged($event, experiment)"
                             [inputClassName]="'table-check-box table-body' + (selected ? ' row-selected' : '')"></sm-checkbox-control>
      </sm-table-card>
    </ng-template>
  </sm-table>
</div>
