<div class="title">
  WEB APP CLOUD ACCESS <i
  class="al-icon al-ico-info-circle sm info ml-3"
  smTooltip="Local, browser cached, access tokens that facilitate retrieving your cloud stored resources"
  matTooltipPosition="above"
></i>
</div>
<div class="section">
  <sm-s3-access
    [s3bucketCredentials]="s3BucketCredentials$ | async"
    (s3bucketCredentialsChanged)="onS3BucketCredentialsChanged($event)">
  </sm-s3-access>
</div>
