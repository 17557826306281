<small class="alert-warning" *ngIf="editName.active && isDev">Renaming a DEV experiment without changing the
  code to reflect the rename, will create a new experiment the next time the code is executed.
</small>
<div class="d-flex">
  <sm-experiment-type-icon-label class="experiment-icon" [showLabel]="false" [type]="experiment?.type" iconClass="xl" >
  </sm-experiment-type-icon-label>
  <div class="middle-col">
    <div class="d-flex align-items-center">
      <sm-inline-edit
        #editName
        class="edit-name"
        [originalText]="infoData?.name || experiment?.name"
        [editable]="editable"
        [minWidth]="250"
        (textChanged)="onNameChanged($event)"
        (inlineActiveStateChanged)="editExperimentName($event)"
      >
        <div class="experiment-name">
          <span>{{experiment?.name}}</span>
        </div>
      </sm-inline-edit>
      <div class="shared"
           *ngIf="showMinimize && shared"
      ><i class="fas fa-link p-1"></i></div>
      <span class="comment"
            smHesitate [delay]="1000" [action]="'leave'"
            (smHesitate)="menuHesitate.hesitateStatus && menu.closed.emit()">
        <i class="al-icon  pointer"
           #commentElement
           [ngClass]="infoData?.comment ? 'al-ico-task-desc' : 'al-ico-task-desc-outline'"
           [matMenuTriggerFor]="menu"
           smHesitate
           [delay]="500"
           [action]="'enter'"
           (smHesitate)="commentElement.click(); menuHesitate.hesitateStatus = true"
           (click)="false"
        ></i>
      </span>


      <mat-menu #menu="matMenu" backdropClass="hello" [hasBackdrop]="false">
        <sm-experiment-info-edit-description
          smHesitate
          #menuHesitate="hesitate"
          [delay]="1000"
          [action]="'leave'"
          (smHesitate)="menu.closed.emit();"
          [selectedExperiment]="infoData"
          (onDescription)="onDescriptionHandler()"
        ></sm-experiment-info-edit-description>
      </mat-menu>
      <i class="al-icon al-ico-id pointer" (click)="showID()" *ngIf="!viewId"></i>
      <span class="experiment-id" *ngIf="viewId">{{experiment.id}}</span>
      <div class="status"
           [class]="experiment?.status"
           *ngIf="showMinimize && getStatusLabel()"
      >{{getStatusLabel()}}</div>
    </div>
    <div class="d-flex align-items-center">
      <span class="experiment-type">{{ experiment?.type | noUnderscore | titlecase }}</span>
      <sm-tag-list
        *ngIf="editable; else readOnlyTags"
        class="w-100"
        [class.menu-open]="!!tagMenu && tagMenu.trigger.menuOpen"
        [sysTags]="systemTags"
        [tags]="experiment?.tags"
        (add)="openTagMenu($event)"
        (remove)="removeTag($event)"></sm-tag-list>
      <ng-template #readOnlyTags>
        <sm-tag-list
          class="w-100"
          [sysTags]="systemTags"
          [tags]="experiment?.tags"></sm-tag-list>
      </ng-template>
      <sm-menu
        #tagMenu
        [showCart]="false"
        [showButton]="false"
        [showOverlay]="false"
        (menuClosed)="tagsMenuClosed()"
      >
        <sm-tags-menu
          class="light-theme"
          #tagsMenuContent
          [tags]="experiment?.tags"
          [tagsFilterByProject]="tagsFilterByProject$ | async"
          [projectTags]="projectTags$ | async"
          [companyTags]="companyTags$ | async"
          (tagSelected)="addTag($event)"
        ></sm-tags-menu>
      </sm-menu>
    </div>
  </div>
  <div class="light-theme right-buttons">
    <button class="btn btn-icon" (click)="minimizeClicked.emit()" *ngIf="showMinimize && !isSharedAndNotOwner"
            smTooltip="View in experiment table" matTooltipPosition="left">
      <i class="al-icon al-ico-info-min al-color light-grey-blue"></i>
    </button>
    <sm-experiment-menu-extended
      *ngIf="showMenu && experiment"
      [experiment]="experiment"
      [selectedExperiment]="experiment"
      [tagsFilterByProject]="tagsFilterByProject$ | async"
      [selectedDisableAvailable]="selectedDisableAvailable"
      [selectedDisableAvailableIsMultiple]="false"
      [projectTags]="projectTags$ | async"
      [companyTags]="companyTags$ | async"
      [minimizedView]="!showMinimize"
      [isSharedAndNotOwner]="isSharedAndNotOwner"
      [numSelected]="1"
      (tagSelected)="addTag($event)"
    ></sm-experiment-menu-extended>
  </div>
</div>
