<div class="row">
  <div class="col-1 d-flex align-items-start justify-content-around content-row">
    <sm-experiment-type-icon-label [type]="experiment?.type" [showLabel]="false"></sm-experiment-type-icon-label>
  </div>
  <div class="col-23">
    <div class="row d-flex content-row justify-content-between">
      <div class="ellipsis name">
        {{experiment.name}}
      </div>
      <i *ngIf="experiment?.system_tags.includes('shared')" class="fas fa-link p-1 ml-1"></i>
      <sm-experiment-status-icon-label [status]="experiment?.status"></sm-experiment-status-icon-label>
    </div>
    <div class="row content-row justify-content-between">
      {{experiment?.user?.name}}
    </div>
    <div class="row d-flex content-row justify-content-between">
      <div>
        {{experiment.created | date : TIME_FORMAT_STRING}}
      </div>
      <div>
        {{experiment.completed | date : TIME_FORMAT_STRING}} {{experimentDuration(experiment.created, experiment.completed) | msToHours}}
      </div>
    </div>
  </div>
</div>
