<sm-editable-section
  #prototext
  [editable]="!model?.ready && model?.company?.id && !isSharedAndNotOwner"
  [saving]="saving"
  [disableInEditMode]="true"
  (activateEditClicked)="activateEdit(); editPrototext()"
  (saveClicked)="saveClicked()"
  (cancelClicked)="cancelEdit()"
>
  <sm-section-header label="MODEL CONFIGURATION"></sm-section-header>
  <div class="light-theme textarea-container">
    <sm-scroll-textarea
      class="form-section"
      [editable]="inEditMode && prototext.inEditMode"
      [isInDev]="false"
      [formData]="design ?? ''"
      [showSpinner]="false"></sm-scroll-textarea>
  </div>
  <button extra-buttons
          *ngIf="design"
          class="btn-dark-fill mr-3 flex-1"
          (click)="clearPrototext()">
    <i class="p-1 fa fa-trash"></i>
    CLEAR
  </button>
</sm-editable-section>
