<sm-experiment-info-header-status-progress-bar
  [status]="(tableSelectedExperiment$| async)?.status || selectedExperiment?.status"
  [editable]="editable$ | async"
  [showMaximize]="true"
  (closeInfoClicked)="deselectExperiment()"
  (maximizedClicked)="maximize()">
</sm-experiment-info-header-status-progress-bar>

<div class="experiment-info-container light-theme">
  <sm-experiment-info-header
    [experiment]="(tableSelectedExperiment$| async) || selectedExperiment"
    [infoData]="infoData$ | async"
    [editable]="!isExample"
    [showMenu]="true"
    [backdropActive]="backdropActive$|async"
    (experimentNameChanged)="updateExperimentName($event)"
  >
  </sm-experiment-info-header>
  <nav>
    <span routerLink="execution" [routerLinkActive]="'disabled'" queryParamsHandling="merge">
      <sm-navbar-item header="execution" [active]="routerConfig.includes('execution')"></sm-navbar-item>
    </span>

    <span [routerLink]="['hyper-params/hyper-param/_empty_']" [class.disabled]="routerConfig.includes('hyper-params')" queryParamsHandling="merge">
      <sm-navbar-item header="configuration" [active]="routerConfig.includes('hyper-params')"></sm-navbar-item>
    </span>
    <span [routerLink]="['artifacts']" [routerLinkActive]="'disabled'" queryParamsHandling="preserve">
      <sm-navbar-item header="artifacts" [active]="routerConfig.includes('artifacts')"></sm-navbar-item>
    </span>
    <span [routerLink]="['general']" [routerLinkActive]="'disabled'" queryParamsHandling="preserve">
      <sm-navbar-item header="info" [active]="routerConfig.includes('general')"></sm-navbar-item>
    </span>
    <span [routerLink]="['info-output']" [routerLinkActive]="'disabled'" queryParamsHandling="preserve">
      <sm-navbar-item header="results" [active]="routerConfig.includes('info-output')"></sm-navbar-item>
    </span>
  </nav>
  <div class="experiment-info-body" #scrollContainer>
    <router-outlet (activate)="onActivate($event, scrollContainer)"></router-outlet>
  </div>
</div>

