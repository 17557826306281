import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sm-workspace-configuration',
  templateUrl: './workspace-configuration.component.html',
  styleUrls: ['./workspace-configuration.component.scss']
})
export class WorkspaceConfigurationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
