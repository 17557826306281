<table class="admin-table" *ngIf="credentials?.length">
  <thead>
    <tr>
      <th>Keys</th>
      <th>Last used</th>
      <th>Worker/IP</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let credential of credentials">
      <td>{{credential.access_key}}</td>
      <td>{{credential?.last_used ? (credential.last_used |  date : TIME_FORMAT_STRING): 'Never'}}</td>
      <td>{{credential?.last_used_from ? credential?.last_used_from : 'Not available'}}</td>
      <td class="text-center">
          <div class="delete-button" (click)="confirmPopUp(credential)" type="button" smTooltip="Revoke">
            <i class="al-icon sm-md  m-auto" [ngClass]="ICONS.REMOVE"></i>
          </div>
      </td>
    </tr>
  </tbody>
</table>

