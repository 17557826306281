<div class="title">APP CREDENTIALS <i
  class="fas fa-info-circle info"
  smTooltip="Credentials providing API access to this workspace"
  matTooltipPosition="above"></i>
</div>
<div class="section mb-4" *ngFor="let cred of credentials$ | async | keyvalue">
  <sm-admin-credential-table [credentials]="cred?.value"
                             (credentialRevoked)="onCredentialRevoked($event)">
  </sm-admin-credential-table>
  <span
    class="add-button d-flex align-items-center pointer"
    [class.disabled]="creatingCredentials"
    (click)="createCredential()"><i class="al-icon sm al-ico-plus mr-1"></i> Create new credentials
  </span>
</div>
