<form [formGroup]="S3Form" *ngIf="S3Form">
  <div class="row s3-row header" *ngIf="bucketCredentials.controls.length>0">
    <div class="col-6">Bucket</div>
    <div class="col-4">Key</div>
    <div class="col-6">Secret / SAS</div>
    <div class="col-2">AWS Region</div>
    <div class="col-6">Host (Endpoint)</div>
  </div>
  <div *ngFor="let bucket of bucketCredentials.controls; let i = index; trackBy: trackByFn"
       formArrayName="bucketCredentials">
    <div class="row s3-row" [formGroupName]="i">
      <div class="col-6">
        <input matInput class="form-control" formControlName="Bucket">
      </div>
      <div class="col-4">
        <input matInput class="form-control" formControlName="Key">
      </div>
      <div class="col-6">
        <input matInput class="form-control" formControlName="Secret">
      </div>
      <div class="col-2">
        <input matInput class="form-control" formControlName="Region" placeholder="us-east-2">
      </div>
      <div class="col-4">
        <input matInput class="form-control" formControlName="Endpoint" placeholder="Leave empty for AWS S3">
      </div>
      <div class="col-2 d-flex justify-content-end">
        <span class="delete-button" (click)="removeBucket(i)" type="button" smTooltip="Remove">
          <i class="al-icon sm-md" [ngClass]="ICONS.REMOVE"></i>
        </span>
      </div>
    </div>
  </div>
  <div>
    <a href="" class="add-button" (click)="$event.preventDefault(); addBucket({})" type="button">
      <i class="al-icon sm al-ico-plus mr-1 vertical-middle"></i>Add access keys
    </a>
  </div>
</form>
