<ng-form #sourceCodeForm="ngForm">
  <div [class.source-code-container]="editable">
    <sm-labeled-row label="REPOSITORY">
      <div *ngIf="!editable">{{formData?.repository}}</div>
      <mat-form-field appearance="outline" *ngIf="editable" class="col-24">
        <input matInput [ngModel]="formData?.repository" name="repository">
      </mat-form-field>
    </sm-labeled-row>
    <sm-labeled-row [label]="editable ? 'COMMIT' : (formData?.scriptType| replaceViaMapPipe:flagNameMap)">
      <div *ngIf="!editable">
        <div [ngSwitch]="formData?.scriptType">
          <div *ngSwitchCase="sourceTypesEnum.VersionNum">
            {{formData?.version_num}} {{formData?.branch ? ' (in branch ' + formData?.branch + ')' : ''}}
          </div>
          <div *ngSwitchCase="sourceTypesEnum.Branch">
            {{formData?.branch ? 'Latest in branch ' + formData?.branch : '' }}
          </div>
          <div *ngSwitchCase="sourceTypesEnum.Tag">
            {{formData?.version_num ? 'Tag ' + formData?.tag + ' (commit id ' + formData?.version_num + ')' : 'Tag ' + formData?.tag }}
          </div>
        </div>
      </div>
      <div class="d-flex" *ngIf="editable">
        <mat-form-field appearance="outline" class="col-12">
          <mat-label>Type</mat-label>
          <mat-select #scriptType [ngModel]="formData?.scriptType" panelClass="light-theme" name="scriptType">
            <mat-option *ngFor="let type of scriptTypeOptions" [value]="type.value">{{type.label}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline"
                        [class.d-none]="scriptType.value !== sourceTypesEnum.VersionNum" class="col-12">
          <mat-error>Required</mat-error>
          <mat-label>Commit ID</mat-label>
          <input matInput type="text"
                 name="version_num"
                 (blur)="resetOtherScriptParameters(sourceTypesEnum.VersionNum)"
                 [ngModel]="formData?.version_num"
                 [required]="(!!formData?.repository) && scriptType.value == sourceTypesEnum.VersionNum"
                 [placeholder]="scriptPlaceHolders[sourceTypesEnum.VersionNum]">
        </mat-form-field>

        <mat-form-field appearance="outline" [class.d-none]="scriptType.value !== sourceTypesEnum.Branch" class="col-12">
          <mat-label>Branch</mat-label>
          <mat-error>Required</mat-error>
          <input matInput type="text"
                 name="branch"
                 (blur)="resetOtherScriptParameters(sourceTypesEnum.Branch)"
                 [ngModel]="formData?.branch"
                 [required]="(!!formData?.repository) && scriptType.value == sourceTypesEnum.Branch"
                 [placeholder]="scriptPlaceHolders[sourceTypesEnum.Branch]">
        </mat-form-field>

        <mat-form-field appearance="outline" [class.d-none]="scriptType.value !== sourceTypesEnum.Tag" class="col-12">
          <mat-error>Required</mat-error>
          <mat-label>Tag</mat-label>
          <input matInput type="text"
                 name="tag"
                 (blur)="resetOtherScriptParameters(sourceTypesEnum.Tag)"
                 [ngModel]="formData?.tag"
                 [required]="(!!formData?.repository) && scriptType.value == sourceTypesEnum.Tag"
                 [placeholder]="scriptPlaceHolders[sourceTypesEnum.Tag]">
        </mat-form-field>

      </div>

    </sm-labeled-row>
    <sm-labeled-row label="SCRIPT PATH">
      <div *ngIf="!editable">{{formData?.entry_point}}</div>
      <mat-form-field appearance="outline" *ngIf="editable" class="col-24">
        <input matInput type="text" [ngModel]="formData?.entry_point" name="entry_point">
      </mat-form-field>
    </sm-labeled-row>
    <sm-labeled-row label="WORKING DIRECTORY">
      <div *ngIf="!editable">{{formData?.working_dir}}</div>
      <mat-form-field appearance="outline" *ngIf="editable" class="col-24">
        <input matInput type="text" [ngModel]="formData?.working_dir" name="working_dir">
      </mat-form-field>
    </sm-labeled-row>
  </div>
</ng-form>
