<textarea
  #networkDesign
  *ngIf="editable"
  class="preview-text resize-none"
  [value]="(formData || formData === 0) ? formData : ''"
></textarea>
<sm-scroll-textarea
  *ngIf="!editable"
  [formData]="formData"
  [editable]="editable"
  [showSpinner]="formData === null"
></sm-scroll-textarea>
