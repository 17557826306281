<sm-overlay [backdropActive]="backdropActive"></sm-overlay>

<div class="d-flex">
  <div class="p-2 icon i-model model-icon xl"></div>
  <div class="middle-col">
    <div class="d-flex align-items-center">
      <sm-inline-edit
        class="edit-name"
        [originalText]="model?.name"
        [editable]="editable"
        [minWidth]="250"
        (textChanged)="onNameChanged($event)"
        (inlineActiveStateChanged)="editExperimentName($event)"
      >
        <div class="model-name">
          <span>{{model?.name}}</span>
        </div>
      </sm-inline-edit>
      <i class="al-icon al-ico-id pointer" (click)="viewId = true" *ngIf="!viewId; else showId"></i>
      <ng-template #showId>
        <span class="model-id">{{model.id}}</span>
      </ng-template>
    </div>
    <div class="tags-list">
      <sm-tag-list
        *ngIf="editable; else readOnlyTags"
        class="w-100"
        [class.menu-open]="tagMenu?.trigger.menuOpen"
        [sysTags]="sysTags"
        [tags]="model?.tags"
        (add)="openTagMenu($event)"
        (remove)="removeTag($event)"></sm-tag-list>
      <ng-template #readOnlyTags>
        <sm-tag-list
          class="w-100"
          [sysTags]="sysTags"
          [tags]="model?.tags"></sm-tag-list>
      </ng-template>
      <sm-menu
        #tagMenu
        [showCart]="false"
        [showButton]="false"
        [showOverlay]="false"
        (menuClosed)="tagsMenuClosed()"
      >
        <sm-tags-menu
          class="light-theme"
          #tagsMenuContent
          [tags]="model?.tags"
          [tagsFilterByProject]="tagsFilterByProject$ | async"
          [projectTags]="projectTags$ | async"
          [companyTags]="companyTags$ | async"
          (tagSelected)="addTag($event)"
        ></sm-tags-menu>
      </sm-menu>
    </div>
  </div>
  <div class="light-theme right-buttons">
    <sm-model-menu-extended
      [model]="model"
      [selectedModel]="model"
      [tagsFilterByProject]="tagsFilterByProject$ | async"
      [selectedDisableAvailableIsMultiple]="false"
      [selectedDisableAvailable]="selectedDisableAvailable"
      [projectTags]="projectTags$ | async"
      [companyTags]="companyTags$ | async"
      [numSelected]="1"
      (tagSelected)="addTag($event)">
    </sm-model-menu-extended>
  </div>
</div>
