<sm-dialog-template [iconClass]="'al-icon ' + (extend ? 'al-ico-extend' : 'al-ico-clone')" [header]="header">
  <div *ngIf="!extend; else extendMessage" class="reference">
    A draft copy of <b *ngIf="reference"
                       [smTooltip]="reference.length > 80 ? reference : undefined">{{reference.length > 80 ? (reference | slice:0:77) + '...' : reference }}</b>
    will be created.
  </div>
  <ng-template #extendMessage>
    <div class="reference">
      A draft copy of <b *ngIf="reference" [smTooltip]="reference.length > 80 ? reference : undefined">{{reference.length > 80 ? (reference | slice:0:77) + '...' : reference }}</b> will be created,
      with it's input model set to the output model of <b *ngIf="reference">{{reference}}</b>.
    </div>
  </ng-template>
  <form #cloneForm="ngForm">
    <div class="form-container">
      <mat-form-field class="w-100"
                      appearance="outline"
                      hideRequiredMarker="true"
                      (click)="filterText && clear(); projectInputRef.blur(); projectInputRef.focus()">
        <mat-label>Project</mat-label>
        <input matInput type="text"
               autocomplete="off"
               (focusin)="clearOnFirstFocus($event);"
               [matAutocomplete]="auto"
               [(ngModel)]="formData.project"
               name="projectName"
               #projectInput="ngModel"
               #projectInputRef
               required
               minlength="3"
               (keydown.enter)="projectInput.control.markAsTouched()"
               smUniqueNameValidator
               [existingNames]="readOnlyProjects$ | async"
        >
        <span matSuffix *ngIf="(!isAutoCompleteOpen) && (!formData.project?.value) && formData.project"
              class="creat-new-suffix">(Create New)</span>
        <i matSuffix [class]="'fa mr2 ' + (filterText? 'fa-times pointer':'fa-search')"
           (click)="filterText && clear(); projectInputRef.focus()"
           smClickStopPropagation></i>
        <mat-error *ngIf="projectInput?.errors?.required">Please provide a Project</mat-error>
        <mat-error *ngIf="projectInput?.errors?.minlength">Please provide a name longer than 3 characters</mat-error>
        <mat-error *ngIf="projectInput?.errors?.uniqueName">Please provide a different name as this project name is
          taken as an Example project
        </mat-error>

        <mat-autocomplete #auto="matAutocomplete"
                          name="ExperimentProject"
                          class="light-theme"
                          [displayWith]="displayFn"
                          (opened)="setIsAutoCompleteOpen(true)"
                          (closed)="setIsAutoCompleteOpen(false)"
                          autoActiveFirstOption
        >
          <mat-option
            class="item"
            *ngIf="filterText && isNewName"
            [value]="filterText"
          >"{{filterText}}" <span class="new">(Create New)</span></mat-option>
          <mat-option *ngFor="let project of filteredProjects | async" [value]="project">{{project.label}}</mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Name</mat-label>
        <input
          required
          matInput
          autocomplete="off"
          #experimentName="ngModel"
          name="ExperimentName"
          [(ngModel)]="formData.name"
        />
        <mat-error *ngIf="experimentName?.errors?.required">Please provide a name</mat-error>
      </mat-form-field>
      <mat-form-field class="w-100" appearance="outline"
                      hideRequiredMarker="true">
        <mat-label>Description</mat-label>
        <textarea
          name="ExperimentComment"
          matInput
          [rows]="7"
          [(ngModel)]="formData.comment"></textarea>
      </mat-form-field>

      <div class="buttons">
        <button cdkFocusInitial (click)="closeDialog(true)" [disabled]="!cloneForm.valid"
                class="btn btn-neon yes-button" #cloneButton>
          {{extend ? 'EXTEND' : 'CLONE'}}
        </button>
        <button (click)="closeDialog(false)" class="btn btn-outline-neon">
          CANCEL
        </button>

      </div>
    </div>
  </form>
</sm-dialog-template>
