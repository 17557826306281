<sm-compare-footer *ngIf="footerState">
  <ng-container *ngFor="let footerItem of footerItems; trackBy: trackBy"   >

      <ng-template
        #templateRef
        [ngTemplateOutletContext]="{ $implicit: footerItem.getItemState(footerState)}"
        [ngTemplateOutlet]="templateRef"
        let-footerItemState
      >
        <ng-container *ngIf="!footerItem.isTag; else tags">
        <span
          *ngIf="!footerItemState?.preventCurrentItem"
          [ngClass]="footerItemState?.wrapperClass"
          matTooltipPosition="above"
          [smTooltip]="footerItem.disable || footerItemState.disable
                                  ? footerItem.disableDescription || footerItemState.disableDescription
                                  : footerItemState.description   || footerItem.description"
        >
          <button
            class="compare-button-container"
            [ngClass]="footerItem.class || footerItemState?.class"
            (click)="footerItem.emit && footerItemClick.emit({item: footerItem, emitValue: footerItemState?.emitValue })"
            [class.disabled]="footerItemState?.disable ? footerItemState?.disable : null"
          >
            <i *ngIf="footerItem.icon" [class]="'sm al-icon ' + footerItem.icon + ' sm-md al-color blue-100'"></i>
            <i *ngIf="footerItemState?.icon" [class]="'sm al-icon ' + footerItemState?.icon + ' sm-md al-color blue-100'"></i>
            <div *ngIf="footerItem.title" class="title">{{footerItem.title}}</div>
            <div *ngIf="footerItemState?.title" class="title ml-1">{{footerItemState?.title}}</div>
          </button>
        </span>
    </ng-container>
    <ng-template #tags>
              <span class="tags-container"
                    matTooltipPosition="above"
                    [smTooltip]="footerItem.disable || footerItemState.disable
                                  ? footerItem.disableDescription || footerItemState.disableDescription
                                  : footerItemState.description   || footerItem.description"
              >
        <button mat-menu-item
                [matMenuTriggerFor]="tagMenu"
                [disabled]="footerItemState.disable"
                (menuOpened)="tagMenuOpened()"
                (menuClosed)="tagMenuClosed()"
        >
        <i [class]="'al-icon ' + icons.TAG + ' sm-md'"></i>
      </button>
      <mat-menu #tagMenu="matMenu">
        <sm-tags-menu
          class="light-theme"
          [tags]="footerItemState.tags"
          [tagsFilterByProject]="footerItemState.tagsFilterByProject"
          [projectTags]="footerItemState.projectTags"
          [companyTags]="footerItemState.companyTags"
          (tagSelected)="tagSelected.emit({tags: $event, emitValue: footerItemState.emitValue})">
        </sm-tags-menu>
      </mat-menu>
      </span>

      </ng-template>
    </ng-template>
  </ng-container>
</sm-compare-footer>
