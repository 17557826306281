<sm-overlay [backdropActive]="backdropActive$|async"></sm-overlay>
<sm-experiment-header
  [tableCols]="filteredTableCols$| async"
  [isArchived]="isArchived$ | async"
  [metricVariants]="metricVariants$ | async"
  [hyperParams]="hyperParams$ | async"
  [minimizedView]="minimizedView"
  [isMetricsLoading]="metricLoading$ | async"
  [autoRefreshState]="autoRefreshState$ | async"
  [tableFilters]="tableFilters$ | async"
  (isArchivedChanged)="onIsArchivedChanged($event)"
  (selectedTableColsChanged)="selectedTableColsChanged($event)"
  (refreshListClicked)="refreshList($event)"
  (getMetricsToDisplay)="getMetricsToDisplay()"
  (selectedMetricToShow)="selectedMetricToShow($event)"
  (selectedHyperParamToShow)="selectedHyperParamToShow($event)"
  (removeColFromList)="removeColFromList($event)"
  (setAutoRefresh)="setAutoRefresh($event)"
  (clearSelection)="clearSelection()"
  (clearTableFilters)="clearTableFiltersHandler($event)"
></sm-experiment-header>
<div class="experiment-body"
     [class.footer-visible]="((selectedExperiments$ | async) && (selectedExperiments$ | async)?.length > 1) || (showAllSelectedIsActive$ |async)">
  <as-split #split
            [useTransition]="true"
            [gutterDblClickDuration]="400"
            (gutterClick)="clickOnSplit()"
            (gutterDblClick)="closeExperimentPanel()"
            (dragEnd)="splitSizeChange($event)"
            (dragStart)="disableInfoPanel()"
            (transitionEnd)="experimentsTable.table?.resize(); experimentsTable.afterTableInit()"
  >
    <as-split-area [size]="100 - (splitInitialSize)"
                   [order]="1">
      <sm-experiments-table
        #experimentsTable
        class="align-self-stretch"
        selectionMode="single"
        [colsOrder]="(tableColsOrder$ | async)"
        [tableCols]="tableCols$| async"
        [experiments]="experiments$ | async"
        [users]="users$ | async"
        [hyperParamsOptions]="hyperParamsOptions$ | async"
        [activeParentsFilter]="activeParentsFilter$ | async"
        [parents]="parent$ | async"
        [experimentTypes]="types$ | async"
        [tags]="tags$ | async"
        [systemTags]="systemTags$ | async"
        [tableSortFields]="(tableSortFields$ | async)"
        [tableSortOrder]="(tableSortOrder$ | async)"
        [tableFilters]="(tableFilters$ | async)"
        [selectedExperiments]="selectedExperiments$ | async"
        [selectedExperimentsDisableAvailable]="selectedExperimentsDisableAvailable$ | async"
        [selectedExperiment]="selectedTableExperiment$ |async"
        [minimizedView]="minimizedView"
        [noMoreExperiments]="noMoreExperiments$ | async"
        [split]="selectSplitSize$ | async"
        (experimentSelectionChanged)="experimentSelectionChanged($event)"
        (experimentsSelectionChanged)="experimentsSelectionChanged($event)"
        (sortedChanged)="sortedChanged($event)"
        (filterChanged)="filterChanged($event)"
        (tagsMenuOpened)="refreshTagsList()"
        (typesMenuOpened)="refreshTypesList()"
        (loadMoreExperiments)="getNextExperiments()"
        (columnsReordered)="columnsReordered($event)"
        (columnResized)="columnResized($event)"
      >
      </sm-experiments-table>
    </as-split-area>
    <as-split-area [size]="splitInitialSize" class="light-theme" [class.info-disabled]="infoDisabled"
                   [visible]="minimizedView" [order]="2">
      <router-outlet></router-outlet>
    </as-split-area>
  </as-split>
</div>

<sm-entity-footer
  [footerState]="footerState$ | async"
  [footerItems]="footerItems"
  (footerItemClick)="onFooterHandler($event)"
  (tagSelected)="tagSelected($event, 'experiments')"
></sm-entity-footer>
