import {Component} from '@angular/core';

@Component({
  selector: 'sm-admin-footer-actions',
  templateUrl: './admin-footer-actions.component.html',
  styleUrls: ['./admin-footer-actions.component.scss']
})
export class AdminFooterActionsComponent {
  constructor() { }
}
