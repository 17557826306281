<sm-experiment-info-header-status-progress-bar
  [status]="getReadyStatus((selectedTableModel$ | async)?.ready || selectedModel?.ready)"
  (closeInfoClicked)="deselectModel()"
>
</sm-experiment-info-header-status-progress-bar>
<div class="model-info-container light-theme">
  <sm-model-info-header #modelInfoHeader
                        [model]="((selectedTableModel$ | async) || selectedModel)"
                        [editable]="!isExample"
                        [backdropActive]="backdropActive$ | async"
                        (deselectModel)="deselectModel()"
                        (modelNameChanged)="updateModelName($event)"
  >
  </sm-model-info-header>
  <nav>
    <span [routerLink]="['general']" routerLinkActive #rlaGeneral="routerLinkActive" queryParamsHandling="merge">
      <sm-navbar-item header="general" [active]="rlaGeneral.isActive"></sm-navbar-item>
    </span>
    <span [routerLink]="['network']" routerLinkActive #rlaNetwork="routerLinkActive" queryParamsHandling="merge">
      <sm-navbar-item header="network" [active]="rlaNetwork.isActive"></sm-navbar-item>
    </span>
    <span [routerLink]="['labels']" routerLinkActive #rlaLabels="routerLinkActive" queryParamsHandling="merge">
      <sm-navbar-item header="labels" [active]="rlaLabels.isActive"></sm-navbar-item>
    </span>
  </nav>
  <div class="model-info-body">
    <router-outlet></router-outlet>
  </div>
</div>
